'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol ? "symbol" : typeof obj; };

(function (global, factory) {
  (typeof exports === 'undefined' ? 'undefined' : _typeof(exports)) === 'object' && typeof module !== 'undefined' ? factory(exports) : typeof define === 'function' && define.amd ? define(['exports'], factory) : factory(global.joinable = global.joinable || {});
})(undefined, function (exports) {
  'use strict';

  function isJoinable(item) {
    return typeof item === 'string' || typeof item === 'number';
  }

  function joinObject(plainObject) {
    var propSeparator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_PROP_SEPARATOR;
    var keyValSeparator = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DEFAULT_KEY_VALUE_SEPARATOR;

    var objectAsArray = [];
    for (var prop in plainObject) {
      if (!plainObject[prop]) continue;
      if (!isJoinable(plainObject[prop])) continue;

      objectAsArray.push(prop + keyValSeparator + plainObject[prop]);
    }

    return objectAsArray.join(propSeparator);
  }

  var DEFAULT_PROP_SEPARATOR = '&';
  var DEFAULT_KEY_VALUE_SEPARATOR = '=';

  var joinStrings = function joinStrings() {
    var stringList = joinable.apply(null, arguments);

    return join(stringList, arguments[arguments.length - 1]);
  };

  var prefixStrings = function prefixStrings() {
    var stringList = joinable.apply(null, arguments),
        prefix = arguments[0],
        lastArg = arguments[arguments.length - 1];

    if (!prefix) return join(stringList, lastArg);

    var prefixList = [];

    for (var i = 1; i < stringList.length; i++) {
      prefixList.push(prefix + stringList[i]);
    }

    return join(prefixList, lastArg);
  };

  var joinExp = function joinExp() {
    var stringList = [];
    var regexp = arguments[0];
    if (!(regexp instanceof RegExp)) throw new Error('First parameter should be of RegExp type');

    for (var i = 1; i < arguments.length; i++) {
      var item = arguments[i];
      if (!item) continue;

      if (isJoinable(item) && isMatch(regexp, item)) {
        stringList.push(item);
      }
    }

    return join(stringList, arguments[arguments.length - 1]);
  };

  var joinable = function joinable() {
    var stringList = [];

    for (var i = 0; i < arguments.length; i++) {
      var item = arguments[i];
      if (!item) continue;

      if (isJoinable(item)) {
        stringList.push(item);
      } else {
        var value = joinIf(item);
        if (value) {
          stringList.push(value);
        }
      }
    }

    return stringList;
  };

  var join = function join(list, options) {
    if (isSeparator(options)) {
      return list.join(options.separator);
    }

    return list.join(DEFAULT_SEPARATOR);
  };

  var joinIf = function joinIf(ifArray) {
    if (!isArray(ifArray)) {
      return null;
    }

    if (ifArray.length === 2) {
      return ifArray[0] ? ifArray[1] : null;
    } else if (ifArray.length === 3) {
      return ifArray[0] ? ifArray[1] : ifArray[2];
    }

    return null;
  };

  var isArray = Array.isArray;
  if (!isArray) {
    isArray = function isArray(arg) {
      return Object.prototype.toString.call(arg) === '[object Array]';
    };
  }

  var isSeparator = function isSeparator(options) {
    return options && (options.separator === '' || options.separator);
  };

  var isMatch = function isMatch(regexp, item) {
    return regexp.test(item);
  };

  var DEFAULT_SEPARATOR = ' ';

  exports['default'] = joinStrings;
  exports.joinStrings = joinStrings;
  exports.joinIf = joinIf;
  exports.prefixStrings = prefixStrings;
  exports.joinExp = joinExp;
  exports.joinObject = joinObject;

  Object.defineProperty(exports, '__esModule', { value: true });
});
